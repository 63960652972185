/* eslint-disable */
<template>
  <div onscroll="animateValue()" id="feature" class="feature-page">
    <div id="down"></div>
    <div class="container">
      <div class="intro">
        <p>
          We are your business partner, not just a marketing agency. Your success is our goal.
        </p>
      </div>
      <div class="number" v-for="item in listFeatureGlobal" :key="item.id">
        <ul>
          <li class="number-1 number11">
            <div class="count-wrp">
              <span id="office" class="count">{{ item.experienced }}
                <span class="plus"><sup>+</sup></span>
              </span>
            </div>
            <span class="text">Experienced</span>
          </li>
          <li class="number-1 number12">
            <div class="count-wrp">
              <span id="employee" class="count"
                >{{ item.partners
                }}<span class="plus"><sup>+</sup></span></span
              >
            </div>
            <span class="text">Partners</span>
          </li>
          <li class="number-1 number13">
            <div class="count-wrp">
              <span id="customer" class="count"
                >{{ item.clients
                }}<span class="plus"><sup>+</sup></span></span
              >
            </div>
            <span class="text">Clients</span>
          </li>
          <li class="number-1 number14">
            <div class="count-wrp">
              <span id="established" class="count">{{ item.costSaving }}</span>
            </div>
            <span class="text">Cost Saving</span>
          </li>
          <li class="number-1 number14">
            <div class="count-wrp">
              <span id="established" class="count">{{ item.customerService }}</span>
            </div>
            <span class="text">Customer Service</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import defaultProject from "../utils/firebase";
export default {
  data() {
    return {
      listFeature: [],
      listFeatureGlobal: [
        {
          experienced: '5 years',
          partners: '1200',
          clients: '50',
          costSaving: '$1M',
          customerService: '24/7'

        }
      ]
    };
  },
  methods: {
    async getFeature() {
      defaultProject
        .firestore()
        .collection("company_info")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data())
            this.listFeature.push(doc.data());
          });
        })
        .catch(() => {
          this.getFeature();
        });
    },
  },
  async mounted() {
    await this.getFeature();
  },
};
</script>

<style lang="scss" scoped>
.feature-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .container {
    width: 100vw;
    .intro {
      padding-top: 11rem;
      padding-bottom: 4.8rem;
      width: 800px;
      margin: 0 auto;
      p {
        font-weight: 400;
        font-size: 2.4rem;
        text-align: center;
        color: #363636;
      }
    }
    .number {
      max-width: 100%;
      padding-bottom: 9.8rem;
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          width: 20%;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
          .count-wrp {
            font-size: 7.2rem;
            display: flex;
            flex-wrap: wrap;
          }
          .count {
            display: flex;
            flex-wrap: wrap;
            font-size: 5.2rem;
            font-weight: 700;
            text-align: center;
            color: #363636;
            .plus {
              font-size: 4.4rem;
            }
          }

          .text {
            font-size: 2.4rem;
            font-weight: 400;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .feature-page {
    width: 100vw;
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100vw;
      .intro {
        width: 100vw;
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
        p {
          font-size: 1.8rem;
          line-height: 2.7rem;
        }
      }
      .number {
        max-width: 100%;
        width: 50vw;
        padding-bottom: 0.8rem;
        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          li {
            flex: 1 1 50%;
            font-size: 1.6rem;
            display: flex;
            align-items: center;

            .count {
              font-size: 4.8rem;
              .plus {
                font-size: 2rem;
              }
            }

            .text {
              font-size: 1.8rem;
              line-height: 2.7rem;
              padding-bottom: 5.6rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: 740px) and (max-width: 1024px) {
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .intro {
        width: 100vw;
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
        justify-content: center;
        display: flex;
        p {
          // width: 30%;
          font-size: 1.8rem;
          line-height: 2.7rem;
        }
      }
    }
    .number {
      max-width: 100%;
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
          flex: 1 1 50%;
          font-size: 4.8rem;
          display: flex;
          align-items: center;
          .count {
            sup {
              font-size: 2.4rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 739px) {
    .feature-page {
      width: 100vw;
      .container {
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .intro {
          width: 76%;
          padding-top: 6.4rem;
          padding-bottom: 6.4rem;
          p {
            font-size: 1.8rem;
            line-height: 2.7rem;
          }
        }
        .number {
          max-width: 100%;
          padding-bottom: 0.8rem;
          width: 80%;
          ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            li {
              flex: 1 1 50%;
              font-size: 1.6rem;
              .count-wrp {
                display: flex;
                justify-content: flex-start;
                .count {
                  font-size: 3.8rem;
                  sup {
                    font-size: 2.4rem;
                  }
                }
                .text {
                  font-size: 1.8rem;
                  padding-bottom: 5.6rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
