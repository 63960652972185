<template>
  <section id="partners">
    <div class="customer">
      <div class="cus-content">
        <h1>Partners</h1>
        <p>We have partnered with top exchanges such as KuCoin, OKX, Bitfinex, Huobi, and many others.</p>
      </div>
      <div class="cus-img">
        <div class="img-item">
          <div class="image1 lienviet_img">
            <a href="https://www.kucoin.com/" target="_blank">
              <img
                  class="item-img pic_big"
                  src="../assets/images/partners/full-kucoin-logo.png"
                  alt=""
              />
            </a>
          </div>
<!--          <div class="image2 lienviet_img">-->
<!--            <a href="https://lienvietpostbank.com.vn/" target="_blank">-->
<!--              <img-->
<!--                  class="pic_big"-->
<!--                  src="../assets/images/cus_hover11.png"-->
<!--                  alt=""-->
<!--              />-->
<!--            </a>-->
<!--          </div>-->
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.okx.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-okx-logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.bitfinex.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-bitfinex-logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.huobi.com/en-us/" target="_blank">
              <img class="item-img" src="../assets/images/partners/huobi-token-seeklogo.com.svg" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.gate.io/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-gate.io-logo.png" alt="" />

            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.bitget.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-bitget-logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.mexc.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-mexc-logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.lbank.info/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-lbank-logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.bitrue.com/home/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-bitrue-logo.png" alt="" />
            </a>
          </div>
        </div>

        <div class="img-item">
          <div class="image1">
            <a href="https://www.bitmart.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-bitmart-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://poloniex.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-poloniex-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://whitebit.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/whitebit-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.bkex.com/en/portal" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-bkex-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.xt.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-xt.com-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.btcex.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/full-btcex-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://latoken.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/latoken.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.coinw.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/CoinW.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://hitbtc.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/HitBTC.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://big.one/en" target="_blank">
              <img class="item-img" src="../assets/images/partners/Full-bigone-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.mybitglobal.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/bitglobal-logo@logotyp.us.svg" alt="" />
            </a>
          </div>
        </div>
<!--        <div class="img-item">-->
<!--          <div class="image1">-->
<!--            <a href="https://www.hotbit.io/" target="_blank">-->
<!--              <img class="item-img" src="../assets/images/partners/full-hotbit-logo.png" alt="" />-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="img-item">
          <div class="image1">
            <a href="https://www.digifinex.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/difinex.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.cointiger.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/CoinTiger.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.bibox.com/en" target="_blank">
              <img class="item-img" src="../assets/images/partners/bibox.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.bitforex.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/BitForex.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.coinstore.com/#/" target="_blank">
              <img class="item-img" src="../assets/images/partners/coinstore.png" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://coinsbit.io/" target="_blank">
              <img class="item-img" src="../assets/images/partners/coinbit.webp" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://www.ztb.im/" target="_blank">
              <img class="item-img" src="../assets/images/partners/zt.webp" alt="" />
            </a>
          </div>
        </div>
        <div class="img-item">
          <div class="image1">
            <a href="https://p2pb2b.com/" target="_blank">
              <img class="item-img" src="../assets/images/partners/p2pb2b_.webp" alt="" />
            </a>
          </div>
        </div>

      </div>
      <div class="sl-mb">
        <div>
          <div class="img-item img-item-mobile">
            <div class="image1 lienviet_img">
              <a href="https://www.kucoin.com/" target="_blank">
                <img
                    class="item-img pic_big"
                    src="../assets/images/partners/full-kucoin-logo.png"
                    alt=""
                />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.okx.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-okx-logo.png" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bitfinex.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-bitfinex-logo.png" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.huobi.com/en-us/" target="_blank">
                <img class="item-img" src="../assets/images/partners/huobi-token-seeklogo.com.svg" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.gate.io/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-gate.io-logo.png" alt="" />

              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bitget.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-bitget-logo.png" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.mexc.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-mexc-logo.png" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.lbank.info/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-lbank-logo.png" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bitrue.com/home/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-bitrue-logo.png" alt="" />
              </a>
            </div>
          </div>

          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bitmart.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-bitmart-logo.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://poloniex.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-poloniex-logo.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://whitebit.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/whitebit-logo.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bkex.com/en/portal" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-bkex-logo.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.xt.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-xt.com-logo.png" alt="" />
              </a>
            </div>
          </div>
<!--          <div class="img-item img-item-mobile">-->
<!--            <div class="image1">-->
<!--              <a href="https://www.hotbit.io/" target="_blank">-->
<!--                <img class="item-img" src="../assets/images/partners/full-hotbit-logo.png" alt="" />-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.btcex.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/full-btcex-logo.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://latoken.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/latoken.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.coinw.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/CoinW.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://hitbtc.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/HitBTC.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://big.one/en" target="_blank">
                <img class="item-img" src="../assets/images/partners/Full-bigone-logo.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.mybitglobal.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/bitglobal-logo@logotyp.us.svg" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.digifinex.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/difinex.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.cointiger.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/CoinTiger.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bibox.com/en" target="_blank">
                <img class="item-img" src="../assets/images/partners/bibox.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.bitforex.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/BitForex.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.coinstore.com/#/" target="_blank">
                <img class="item-img" src="../assets/images/partners/coinstore.png" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://coinsbit.io/" target="_blank">
                <img class="item-img" src="../assets/images/partners/coinbit.webp" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://www.ztb.im/" target="_blank">
                <img class="item-img" src="../assets/images/partners/zt.webp" alt="" />
              </a>
            </div>
          </div>
          <div class="img-item img-item-mobile">
            <div class="image1">
              <a href="https://p2pb2b.com/" target="_blank">
                <img class="item-img" src="../assets/images/partners/p2pb2b_.webp" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.sl-mb {
  display: none;
}
.customer {
  width: 100%;
  padding-top: 110px;
  top: 30rem;
  background: #fafafa;
  background-repeat: no-repeat;
  background-size: cover;
}

.cus-content {
  text-align: center;
}

.cus-content h1 {
  margin: auto;
  font-size: 1.6rem;
  line-height: 1.92rem;
  font-style: normal;
  letter-spacing: 5%;
  color: #999999;
  font-weight: 400;
  font-weight: 400;
  padding-bottom: 5px;
}

.cus-content p {
  font-size: 36px;
  font-weight: 600;
  line-height: 4.773rem;
  color: #27282b;
  max-width: 972px;
  width: 100%;
  margin: 0 auto;
}

.cus-img {
  margin: 48px auto auto;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1300px;
}

.cus-img .img-item {
  height: 170px;
  flex-basis: 20%;
  justify-content: center;
  position: relative;
  /* border: 0.3px solid rgb(238, 227, 227); */
  display: flex;
  align-items: center;
  cursor: pointer;
  object-fit: cover;
}

.img-item .pic_big {
  width: 230px !important;
}

.img-item .lienviet_img {
  margin: 0px !important;
}

.cus-img .img-item img {
  width: 85%;
}

.cus-img .img-item .image1 {
  margin-left: 40px;
}

.cus-img .img-item:hover .image2 {
  margin-left: 40px;
}

/*.cus-img .img-item:hover .image1 {*/
/*  display: none;*/
/*}*/

.cus-img .img-item:hover .image2 {
  display: block;
}

.cus-img .img-item .image2 {
  display: none;
  /* opacity: 1; */
}

.cus-img .img-item .item-img:hover img {
  width: 94%;
  cursor: pointer;
}
@media screen and (max-width: 739px) {
  .img-item-mobile .image1 {
    width: 123px;
  }
  .customer {
    padding-top: 60px !important;
  }
  .cus-img {
    display: none;
  }

  .sl-mb {
    display: block;
    object-fit: cover;
    display: flex;
    /*align-items: center;*/
    justify-content: space-around;
  }

  .cus-content {
    text-align: inherit;
    position: relative;
    left: 2rem;
    padding-right: 2rem;
  }
  .img-item {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-item-mobile .img-small {
    padding: 20px !important;
  }
  .img-item .pic_big {
    height: 41px !important;
    display: flex;
  }

  .pic_wid {
    width: 150px !important;
  }
}

@media (min-width: 740px) and (max-width: 1024px) {
  .img-item {
    height: 120px !important;
  }

  .img-item .item-img {
    padding: 10px 15px;
    object-fit: contain;
  }
}
</style>
